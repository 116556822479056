import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import loadable from '@loadable/component';
import { hide } from 'state/modules/dialog';
import dialogsNames from 'utils/constants/dialogs/dialogsNames';

const ChangePasswordDialog = loadable(() => import(/* webpackChunkName: "ChangePasswordDialog" */ 'containers/Account/ChangePasswordDialog'));
const ErrorDialog = loadable(() => import(/* webpackChunkName: "ErrorDialog" */ 'components/Dialog/ErrorDialog'));
const GuestFormDialog = loadable(() => import(/* webpackChunkName: "GuestFormDialog" */ 'containers/Booking/Guests/GuestFormDialog'));
const GuestMaxOccupancyDialog = loadable(() => import(/* webpackChunkName: "MaxOccupancyDialog" */ 'containers/Booking/Guests/MaxOccupancyDialog'));
const GuestSuccessDialog = loadable(() => import(/* webpackChunkName: "GuestSuccessDialog" */ 'components/Dialog/GuestSuccessDialog'));
const GuestTemplatesDialog = loadable(() => import(/* webpackChunkName: "GuestTemplatesDialog" */ 'containers/Booking/Guests/GuestTemplatesDialog'));
const LoginFailDialog = loadable(() => import(/* webpackChunkName: "LoginFailDialog" */ 'containers/Login/LoginFailDialog'));
const SuccessDialog = loadable(() => import(/* webpackChunkName: "SuccessDialog" */ 'components/Dialog/SuccessDialog'));

const Dialog = ({ dialogName, dialogProps, zIndex }) => {
  const dispatch = useDispatch();

  const handleClose = dialogName => {
    if (typeof dialogProps?.onClose === 'function') {
      dialogProps.onClose();
    }
    dispatch(hide(dialogName));
  };

  const getComponent = () => {
    switch (dialogName) {
      case dialogsNames.CHANGE_PASSWORD:
        return ChangePasswordDialog;
      case dialogsNames.GUEST_EXISTING:
        return GuestTemplatesDialog;
      case dialogsNames.GUEST_FORM:
        return GuestFormDialog;
      case dialogsNames.GUEST_MAXIMUM:
        return GuestMaxOccupancyDialog;
      case dialogsNames.LOGIN_FAIL:
        return LoginFailDialog;
      case dialogsNames.ERROR:
        return ErrorDialog;
      case dialogsNames.GUEST_SUCCESS:
        return GuestSuccessDialog;
      case dialogsNames.SUCCESS:
        return SuccessDialog;
      default:
        return null;
    }
  };

  const Component = getComponent();

  if (Component === null) {
    // An unsupported dialog, identified by name, was specified
    return null;
  }

  return (
    <Component
      // Each dialog requires different values as props,
      // we spread the props to avoid manually setting each prop for each dialog
      // (we may even have more dialogs in the future)
      // i.e.:
      // - GuestTemplates uses bookingId and currentGuests
      // - GuestForm uses actions required, bookingId, guests, title
      // - SuccessMessage and ErrorMessage uses successMessage and errorMessage strings respectively
      open
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dialogProps}
      zIndex={zIndex}
      onClose={() => handleClose(dialogName)}
    />
  );
};

Dialog.propTypes = {
  dialogName: PropTypes.string.isRequired,
  dialogProps: PropTypes.object,
  zIndex: PropTypes.number,
};

export default React.memo(Dialog);
