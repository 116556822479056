import { isBookingSessionDirty, restoreBookingSession } from 'utils/bookingSession';
import { parseGuestRequestBody } from 'utils/guest';
import config from 'appConfig';
import types from './types';

const {
  ADD_GUEST, ADD_GUEST_SUCCESS, ADD_GUEST_FAIL,
  UPDATE_GUEST, UPDATE_GUEST_SUCCESS, UPDATE_GUEST_FAIL,
  REMOVE_GUEST, REMOVE_GUEST_SUCCESS, REMOVE_GUEST_FAIL,
  REMOVE_BOOKER, REMOVE_BOOKER_SUCCESS, REMOVE_BOOKER_FAIL,
  LOAD, LOAD_SUCCESS, LOAD_FAIL, LOAD_SUCCESS_SESSION_STORAGE,
  LOAD_ONE, LOAD_ONE_SUCCESS, LOAD_ONE_FAIL,
  UPDATE_FOLIO, UPDATE_FOLIO_SUCCESS, UPDATE_FOLIO_FAIL,
  CLEAN_UP,
} = types;

/*
  IMPORTANT!

  We use a flag in session storage to see if we made any updates to the bookings in lifeline.
  On the first load the bookings are dirty, and we load from back-end. If the bookings are clean we load from session storage.

  Make sure to update the flag in the reducer on any successful update to bookings in the API.
*/
export function loadBookings() {
  // If bookings are dirty or somehow there are no bookings in session storage, load from lifeline
  if (isBookingSessionDirty()) {
    return {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      promise: client => client.get('/GuestBookings/bookings'),
    };
  }
  // bookings are clean, restore from session storage
  return {
    type: LOAD_SUCCESS_SESSION_STORAGE,
    result: restoreBookingSession(),
  };
}

export function loadOneBooking(bookingId) {
  return {
    types: [LOAD_ONE, LOAD_ONE_SUCCESS, LOAD_ONE_FAIL],
    promise: client => client.get(`/GuestBookings/${bookingId}`),
  };
}

export function addGuest(data, bookingId) {
  const [encryptedData, encryptedFile] = parseGuestRequestBody(data);
  return {
    types: [ADD_GUEST, ADD_GUEST_SUCCESS, ADD_GUEST_FAIL],
    passportUpload: encryptedFile !== null,
    promise: client => client.post(`/GuestBookings/${bookingId}/Guest`, { data: encryptedData, file: encryptedFile, timeout: config.serverTimeout }),
  };
}

export function updateGuestDetails(data, bookingId) {
  const [encryptedData, encryptedFile] = parseGuestRequestBody(data);
  return {
    types: [UPDATE_GUEST, UPDATE_GUEST_SUCCESS, UPDATE_GUEST_FAIL],
    cmsGuestId: data?.id,
    passportUpload: encryptedFile !== null,
    promise: client => client.patch(`/GuestBookings/${bookingId}/Guest`, { data: encryptedData, file: encryptedFile, timeout: config.serverTimeout }),
  };
}

export function removeBooker(bookingId, removeBooking, successMessage) {
  return {
    types: [REMOVE_BOOKER, REMOVE_BOOKER_SUCCESS, REMOVE_BOOKER_FAIL],
    promise: client => client.delete(`/GuestBookings/${bookingId}`),
    removeBooking,
    successMessage,
  };
}

export function removeGuest(cmsGuestId, bookingId) {
  return {
    types: [REMOVE_GUEST, REMOVE_GUEST_SUCCESS, REMOVE_GUEST_FAIL],
    promise: client => client.delete(`/GuestBookings/remove-guest/?folioId=${bookingId}&guestId=${cmsGuestId}`),
  };
}

export function updateFolio(data) {
  return {
    types: [UPDATE_FOLIO, UPDATE_FOLIO_SUCCESS, UPDATE_FOLIO_FAIL],
    promise: client => client.patch('/GuestBookings/updateFolio', { data }),
  };
}

export function cleanup() {
  return { type: CLEAN_UP };
}
