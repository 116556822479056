import types from './types';

const {
  INIT_SUCCESS, INIT_FAIL,
  PAYMENT_SUCCESS, PAYMENT_FAIL,
  SET_AMOUNT, CLEAN_UP,
} = types;

export const initialState = {
  initialized: false,
  completed: false,
  selectedAmount: null,
  paymentReference: null,
  error: null,
  providers: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_SUCCESS:
      return {
        ...state,
        initialized: true,
      };
    case INIT_FAIL:
      return {
        ...state,
        initialized: false,
        error: action.initError,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        completed: true,
        paymentReference: action.paymentReference,
        selectedAmount: action.amount,
      };
    case PAYMENT_FAIL:
      return {
        ...state,
        error: action.paymentError,
      };
    case SET_AMOUNT:
      return {
        ...state,
        selectedAmount: action.amount,
      };
    case CLEAN_UP: {
      // Avoid loosing the providers, which are set on the server-side
      const { providers } = state;
      return {
        ...initialState,
        providers,
      };
    }
    default:
      return state;
  }
}
