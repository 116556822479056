import usePush from './usePush';

const usePushProfile = () => {
  const pushPath = usePush();

  const pushLogin = () => {
    pushPath('/profile');
  };
  return pushLogin;
};

export default usePushProfile;
