const types = {
  INIT_SUCCESS: 'app/payment/INIT_SUCCESS',
  INIT_FAIL: 'app/payment/INIT_FAIL',
  PAYMENT_SUCCESS: 'app/payment/PAYMENT_SUCCESS',
  PAYMENT_FAIL: 'app/payment/PAYMENT_FAIL',
  SET_AMOUNT: 'app/payment/SET_AMOUNT',
  CLEAN_UP: 'app/payment/CLEAN_UP',
};

export default types;
