import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const ForgotPassword = loadable(() => import(/* webpackChunkName: "ForgotPassword" */ './ForgotPassword'), {
  fallback: <Loader visible />,
});

const AsyncForgotPassword = () => (
  <ErrorBoundary>
    <ForgotPassword />
  </ErrorBoundary>
);

export default AsyncForgotPassword;
