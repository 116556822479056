const types = {
  LOAD_OWN: 'app/guest/LOAD_OWN',
  LOAD_OWN_SUCCESS: 'app/guest/LOAD_OWN_SUCCESS',
  LOAD_OWN_FAIL: 'app/guest/LOAD_OWN_FAIL',
  LOAD_OTHERS: 'app/guest/LOAD_OTHERS',
  LOAD_OTHERS_SUCCESS: 'app/guest/LOAD_OTHERS_SUCCESS',
  LOAD_OTHERS_FAIL: 'app/guest/LOAD_OTHERS_FAIL',
  UPDATE: 'app/guest/UPDATE',
  UPDATE_SUCCESS: 'app/guest/UPDATE_SUCCESS',
  UPDATE_FAIL: 'app/guest/UPDATE_FAIL',
  CLEAN_UP: 'app/guest/CLEAN_UP',
  UPDATE_CLEAR: 'app/guest/UPDATE_CLEAR',
  CLEAR_PROFILE: 'app/guest/CLEAR_PROFILE',
  CLEAR_TEMPLATES: 'app/guest/CLEAR_TEMPLATES',
};

export default types;
