import types from './types';

const { SHOW, HIDE } = types;
const INIT_Z_INDEX = 1300;

const initialState = {
  dialogs: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW: {
      const dialogs = [
        ...state.dialogs,
        {
          ...action,
          zIndex: INIT_Z_INDEX + state.dialogs.length,
        },
      ];
      return { dialogs };
    }
    case HIDE: {
      const dialogs = state.dialogs.filter(dialog => dialog.dialogName !== action.dialogName);
      return { dialogs };
    }
    default:
      return state;
  }
}
