const types = {
  CLEAN_UP: 'app/manager/CLEAN_UP',
  CLEAR_ERROR: 'app/auth/CLEAR_ERROR',
  CLEAN_SIGN_UP: 'app/auth/CLEAN_SIGN_UP',
  CLEAN_UPDATE: 'app/auth/CLEAN_UPDATE',
  SIGNUP: 'app/auth/SIGNUP',
  SIGNUP_SUCCESS: 'app/auth/SIGNUP_SUCCESS',
  SIGNUP_FAIL: 'app/auth/SIGNUP_FAIL',
  LOGIN: 'app/auth/LOGIN',
  LOGIN_SUCCESS: 'app/auth/LOGIN_SUCCESS',
  LOGIN_FAIL: 'app/auth/LOGIN_FAIL',
  LOGOUT: 'app/auth/LOGOUT',
  READY: 'app/auth/READY',
  REQUEST: 'app/auth/REQUEST',
  REQUEST_SUCCESS: 'app/auth/REQUEST_SUCCESS',
  REQUEST_FAIL: 'app/auth/REQUEST_FAIL',
  RESET: 'app/auth/RESET',
  RESET_SUCCESS: 'app/auth/RESET_SUCCESS',
  RESET_EMAIL_SUCCESS: 'app/auth/RESET_EMAIL_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'app/auth/RESET_PASSWORD_SUCCESS',
  RESET_FAIL: 'app/auth/RESET_FAIL',
  RESTORE_LOGIN: 'app/auth/RESTORE_LOGIN',
  SEND_VERIFICATION_EMAIL: 'app/auth/SEND_VERIFICATION_EMAIL',
  SEND_VERIFICATION_EMAIL_SUCCESS: 'app/auth/SEND_VERIFICATION_EMAIL_SUCCESS',
  SEND_VERIFICATION_EMAIL_FAIL: 'app/auth/SEND_VERIFICATION_EMAIL_FAIL',
  SSO_SIGNUP: 'app/auth/SSO_SIGNUP',
  SSO_LOGIN: 'app/auth/SSO_LOGIN',
  SSO_FAIL: 'app/auth/SSO_FAIL',
  SSO_CALLBACK: 'app/auth/SSO_CALLBACK',
  SSO_CALLBACK_SUCCESS: 'app/auth/SSO_CALLBACK_SUCCESS',
  SSO_INIT: 'app/auth/SSO_INIT',
  VERIFY: 'app/auth/VERIFY',
  VERIFY_SUCCESS: 'app/auth/VERIFY_SUCCESS',
  VERIFY_FAIL: 'app/auth/VERIFY_FAIL',
  VERIFY_TOKEN: 'app/auth/VERIFY_TOKEN',
  VERIFY_TOKEN_SUCCESS: 'app/auth/VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAIL: 'app/auth/VERIFY_TOKEN_FAIL',
  UPDATE_STATUS_RESET: 'app/user/UPDATE_RESET',
  SEND_LOGIN_LINK: 'app/user/SEND_LOGIN_LINK',
  SEND_LOGIN_LINK_SUCCESS: 'app/user/SEND_LOGIN_LINK_SUCCESS',
  SEND_LOGIN_LINK_FAIL: 'app/user/SEND_LOGIN_LINK_FAIL',
  RESET_LINK_STATE: 'app/user/RESET_LINK_STATE',
  SET_REDIRECTED: 'app/user/SET_REDIRECTED',
  SESSION_REFRESH: 'app/auth/SESSION_REFRESH',
};

export default types;
