import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const Login = loadable(() => import(/* webpackChunkName: "Login" */ './Login'), {
  fallback: <Loader visible />,
});

const AsyncLogin = () => (
  <ErrorBoundary>
    <Login />
  </ErrorBoundary>
);

export default AsyncLogin;
