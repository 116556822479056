import dialogTypes from 'state/modules/dialog/types';

const { HIDE } = dialogTypes;

export default ({ getState }) => next => action => {
  const { type } = action;

  switch (type) {
    case HIDE: {
      const { dialog: dialogState } = getState();
      const dialog = dialogState.dialogs.find(dialog => dialog.dialogName === action.dialogName);
      if (typeof dialog?.dialogProps?.onClose === 'function') {
        dialog.dialogProps.onClose();
      }
      break;
    }
    default:
      break;
  }

  next(action);
};
