import types from './types';

const {
  LOAD_OWN, LOAD_OWN_SUCCESS, LOAD_OWN_FAIL,
  LOAD_OTHERS, LOAD_OTHERS_SUCCESS, LOAD_OTHERS_FAIL,
  UPDATE, UPDATE_SUCCESS, UPDATE_FAIL,
  CLEAN_UP, UPDATE_CLEAR, CLEAR_TEMPLATES, CLEAR_PROFILE,
} = types;

const initialState = {
  loading: false,
  loaded: false,
  loadedTemplates: false,
  loadingTemplates: false,
  updating: false,
  updated: false,
  profile: null,
  templates: [],
  passportUpload: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_OWN:
      return {
        ...state,
        loading: true,
        loaded: false,
        profile: null,
        error: null,
      };
    case LOAD_OWN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        profile: action.result,
      };
    case LOAD_OWN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOAD_OTHERS:
      return {
        ...state,
        loadingTemplates: true,
        loadedTemplates: false,
        templates: [],
        error: null,
      };
    case LOAD_OTHERS_SUCCESS:
      return {
        ...state,
        loadingTemplates: false,
        loadedTemplates: true,
        templates: action.result,
      };
    case LOAD_OTHERS_FAIL:
      return {
        ...state,
        loadingTemplates: false,
        loadedTemplates: false,
        error: action.error,
      };
    case UPDATE:
      return {
        ...state,
        updating: true,
        updated: false,
        passportUpload: action.passportUpload,
        error: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        profile: { ...state.profile, ...action.result },
      };
    case UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        error: action.error,
      };
    case CLEAN_UP:
      return initialState;
    case UPDATE_CLEAR:
      return {
        ...state,
        updating: false,
        updated: false,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        loading: false,
        loaded: false,
        profile: null,
      };
    case CLEAR_TEMPLATES:
      return {
        ...state,
        loadingTemplates: false,
        loadedTemplates: false,
        templates: [],
      };
    default:
      return state;
  }
}
