import PropTypes from 'prop-types';
import React from 'react';
import FormattedTranslation from '@htmniseko/htm-ui/Intl/FormattedTranslationMui';
import { Button, Grid, Link, Slide, Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => (
    {
      appBar: {
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
        padding: '5px 0',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          left: 0,
          right: 0,
        },
      },
      enhancedAppBar: {
        [theme.breakpoints.down('xs')]: {
          zIndex: 50,
          bottom: '3.5rem',
        },
      },
      buttonLabel: {
        textTransform: 'uppercase',
      },
      link: {
        fontSize: theme.typography.pxToRem(13),
        margin: '0 15px',
        [theme.breakpoints.down('xs')]: {
          margin: '0 5px',
        },
      },
      text: {
        color: theme.palette.cookiesConsent.text,
        lineHeight: '1.5',
        [theme.breakpoints.down('xs')]: {
          textAlign: 'center',
        },
      },
    }),
  {
    name: 'CookiesConsentBar',
  }
);

const CookiesConsentBar = ({ onAccept, open, url, isAuthenticated }) => {
  const classes = useStyles();
  return (
    <Snackbar open={open} TransitionComponent={Slide} className={`${classes.appBar} ${isAuthenticated && classes.enhancedAppBar}`}>
      {/* We need a fragment to avoid the Snackbar spreading invalid props into the Grid */}
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
        <Grid item xs={12} sm="auto">
          <Typography className={classes.text} variant="subtitle1">
            <FormattedTranslation id="cookies.consent.usage" />
          </Typography>
        </Grid>
        <Grid item>
          <Link className={`${classes.text} ${classes.link}`} href={url} underline="always">
            <FormattedTranslation id="cookies.consent.moreInfo" />
          </Link>
        </Grid>
        <Grid item>
          <Button classes={{ label: classes.buttonLabel }} size="small" variant="contained" onClick={onAccept}>
            <FormattedTranslation id="general.ok" />
          </Button>
        </Grid>
      </Grid>
    </Snackbar>
  );
};

CookiesConsentBar.propTypes = {
  onAccept: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
};

export default CookiesConsentBar;
