/*
  Dev notes:
  This component is created to provide an easier and more readable code to be used. This component is technically
  a wrapper for Paper component.
  Instead of putting <Paper elevation={0} in each component, it's easier to just use <Panel> which returns the same thing
  but reduce the confusion of what elevation={0} does.
  Using elevation={0} basically allow us to overwrite material-ui CSS for paper for our own theming needs as there is no
  other variants left for us to use.
*/

import PropTypes from 'prop-types';
import React from 'react';
import { Box, Paper } from '@material-ui/core';

const Panel = ({ outlined, gutterBottom, noBorders, noPaper, square, width, children }) => {
  if (outlined) {
    return (
      <Box mb={gutterBottom ? 1 : undefined} border={noBorders ? 0 : 1} borderColor="grey.300" borderRadius={square ? undefined : 'borderRadius'} width={width}>
        {noPaper && children}
        {!noPaper && <Paper variant="outlined" square={square}>{children}</Paper>}
      </Box>
    );
  }
  return (
    <Box mb={gutterBottom ? 1 : undefined} borderRadius="borderRadius">
      {noPaper && children}
      {!noPaper && <Paper elevation={0}>{children}</Paper>}
    </Box>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  noBorders: PropTypes.bool,
  noPaper: PropTypes.bool, // Wrap the children inside MUI Paper or not
  outlined: PropTypes.bool,
  square: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  width: PropTypes.string,
};

Panel.defaultProps = {
  noBorders: false,
  noPaper: false,
  outlined: false,
  square: false,
  gutterBottom: false,
};

export default Panel;
