import { canUseDOM } from 'utils/misc';

const DEFAULT_ROOT_PATH_INDEX = 2; // Index for root path '/en/root -> [0]/[1]/[2]';

export function removeLocalePath(path) {
  // If path has locale, remove it
  return (path.startsWith('/ja') || path.startsWith('/en')) ? path.slice(3) : path;
}

export function currentUrl() {
  if (!canUseDOM()) {
    return null;
  }
  return `${window.location.origin}${window.location.pathname}`;
}

// Retrieve path key from the react router location.
export function getCurrentPath(location, fallbackLocation, rootPathIndex = DEFAULT_ROOT_PATH_INDEX) {
  // If location is not found in the index, return the fallback location instead.
  return location.pathname.split('/')[rootPathIndex] || fallbackLocation;
}
