import moment from 'moment';

// Custom moment date formats that used in the system
export const momentDateFormats = {
  en: {
    longDateFormat: {
      L: 'YYYY-MM-DD', // 2019-07-29 (We render 29/07/2019 in the application but FIRST we need to pass down to the component in YYYY-MM-DD format)
      l: 'D MMMM YYYY', // 29 July 2019
      ll: 'ddd D MMM', // Mon 29 Jul
      LL: 'D MMM YYYY', // 29 Jul 2019
      LLL: 'dddd, D MMM YYYY', // Monday, 29 Jul 2019
      LLLL: 'D MMM YYYY (ddd)', // 29 Jul 2019 (Mon)
    },
  },
  ja: {
    longDateFormat: {
      L: 'YYYY-MM-DD', // 2019-07-29 (We render 29/07/2019 in the application but FIRST we need to pass down to the component in YYYY-MM-DD format)
      l: 'YYYY年M月D日', // 2019年7月29日
      ll: 'M月D日 (ddd)', // 7月29日 (月)
      LL: 'YY年M月D日', // 2019年7月29日
      LLL: 'dddd, YYYY年M月D日', // 月曜日, 2019年7月29日
      LLLL: 'YYYY年M月D日 (ddd)', // 2019年7月29日 (月曜日)
    },
  },
};

export function toShortDate(date) {
  return moment(date).format('L');
}

export function toShortDateAlternative(date) {
  return moment(date).format('ll');
}

export function toMediumDate(date) {
  return moment(date).format('LL');
}

export function toFullDate(date) {
  return moment(date).format('l');
}

export function toDayLongFullDate(date) {
  return moment(date).format('LLL');
}

export function toDayFullDate(date) {
  return moment(date).format('LLLL');
}
