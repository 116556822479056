import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const usePush = () => {
  const { locale } = useIntl();
  const navigate = useNavigate();

  const push = (path, replace = false) => {
    // Only include replace object in navigate hook if explicitly replacing the current URL
    if (replace) {
      navigate(`/${locale}${path}`, { replace });
    }
    else {
      navigate(`/${locale}${path}`);
    }
  };
  return push;
};
export default usePush;
