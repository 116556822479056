import isEmpty from 'lodash/isEmpty';
import bookingTypes from 'utils/constants/booking/bookingTypes';
import { decryptGuest } from './guest';

export function getAllBookingGuests(bookings) {
  // Guest will be repeated if the same guest is in different bookings
  return bookings.reduce((acc, booking) => {
    if (Array.isArray(booking.guests)) {
      booking.guests.forEach(guest => {
        acc.push(guest);
      });
    }

    return acc;
  }, []);
}

// Return true if the guest have more than one bookings.
export function guestHaveMoreThanOneBooking(cmsGuestId, upcomingBookings) {
  const cmsFolioGuests = getAllBookingGuests(upcomingBookings);
  const matchedGuests = cmsFolioGuests.filter(guest => guest.id === cmsGuestId);
  return (Array.isArray(matchedGuests) && matchedGuests.length > 1);
}

// Get the primary guest from a list of guests
export function getPrimaryGuest(guests) {
  const primaryGuest = guests.find(guest => guest.primaryGuest === true) || guests[0];
  return primaryGuest;
}

// Get the name of the primary guest from a list of guests
export function getPrimaryGuestName(guests) {
  const primaryGuest = getPrimaryGuest(guests);
  return primaryGuest?.displayName ?? '';
}

// Get the pax data from a booking
export function getPaxFromBooking(booking) {
  const accomDetail = Array.isArray(booking?.accomDetails) && booking.accomDetails.length > 0 ? booking.accomDetails[0] : {};
  const { adults = 0, children = 0, infants = 0 } = accomDetail;
  return { adults, children, infants };
}

/*
  To reduce the number of API calls to lifeline when certain actions are successful, make modification in the store
  directly after the action is successful. This remove the need to retrieve new data from lifeline every time we perform an
  action to refresh the page with updated information.
*/
export function updateBooking(bookings, updatedBooking) {
  // If any of these data is undefined, return the original bookings.
  if (isEmpty(updatedBooking)) {
    return bookings;
  }

  if (!bookings) {
    return { [bookingTypes.upcoming]: [updatedBooking] };
  }

  const upcomingBookings = bookings[bookingTypes.upcoming];
  const historyBookings = bookings[bookingTypes.history];

  if (!Array.isArray(upcomingBookings) || upcomingBookings.length === 0) {
    return { [bookingTypes.upcoming]: [updatedBooking], [bookingTypes.history]: historyBookings };
  }

  const updatedUpcoming = upcomingBookings.map(booking => {
    // Return booking if it is not the one we looking for
    if (updatedBooking.id !== booking.id) {
      return booking;
    }
    // The updated booking was found, set the updated data
    return updatedBooking;
  });

  return { [bookingTypes.upcoming]: updatedUpcoming, [bookingTypes.history]: historyBookings };
}

export function decryptBooking(booking) {
  const { guests, ...rest } = booking;
  const decryptedGuests = guests.map(guest => decryptGuest(guest));
  return { ...rest, guests: decryptedGuests };
}
