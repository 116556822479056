import { showSuccess } from 'state/modules/dialog';
import types from 'state/modules/auth/types';
import { setAuthCookie } from 'utils/cookies/setCookie';
import { logout } from './actions';

const {
  LOGIN_SUCCESS, SEND_VERIFICATION_EMAIL_SUCCESS, SSO_CALLBACK_SUCCESS,
  SESSION_REFRESH, SSO_INIT, SIGNUP_SUCCESS,
} = types;

export default ({ dispatch, getState }) => next => action => {
  const { result, type } = action;

  switch (type) {
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      dispatch(showSuccess('page.login.verificationEmail.sent'));
      break;
    case SESSION_REFRESH:
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case SSO_CALLBACK_SUCCESS: {
      let ttl;
      let created;

      const { auth } = getState();

      // user account is not immediately verified for non SSO signup
      if (type === SIGNUP_SUCCESS && !result.sso) {
        next(action);
        return;
      }

      // user tried to login using SSO but account is not yet created
      if (type === SSO_CALLBACK_SUCCESS && auth.loggingIn && !result.created) {
        next(action);
        return;
      }

      if (type === SESSION_REFRESH) {
        ({ ttl, created } = auth.session);
      }
      else {
        const { id } = result;
        ({ created } = result);
        // The `guestToken_ttl` is used when the response is a Gateway multi-session object
        // If not, it will use the `ttl` since the response is only a Gateway single-session object from Guest API
        ttl = result.guestToken_ttl ?? result.ttl;

        const expireAt = new Date();
        expireAt.setSeconds(expireAt.getSeconds() + ttl);
        setAuthCookie(id, expireAt);
      }

      const elapsedTime = new Date() - new Date(created);
      const remainingTime = (ttl * 1000) - elapsedTime;

      // Redirect user to login when session expires
      setTimeout(() => { dispatch(logout()); }, remainingTime);
      break;
    }
    case SSO_INIT: {
      // Redirect to the SSO provider when SSO is initiated
      window.location.href = action.result.location;
      break;
    }
    default:
      break;
  }

  next(action);
};
