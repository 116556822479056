import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const BookingList = loadable(() => import(/* webpackChunkName: "BookingList" */ './BookingList'), {
  fallback: <Loader visible />,
});

const asyncBookingList = () => (
  <ErrorBoundary>
    <BookingList />
  </ErrorBoundary>
);

export default asyncBookingList;
