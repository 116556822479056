import usePush from './usePush';

const PATH = '/bookings';
export const GUESTS_PATH = 'guests';

const usePushBooking = () => {
  const pushPath = usePush();

  const pushBooking = (bookingId, tab = '', value = '', replace = false) => {
    if (!bookingId) {
      pushPath(PATH, replace);
      return;
    }
    const subPaths = [bookingId];
    if (tab) {
      subPaths.push(tab);
      if (value) {
        subPaths.push(value);
      }
    }
    pushPath(`${PATH}/${subPaths.join('/')}`, replace);
  };
  return pushBooking;
};

export default usePushBooking;
