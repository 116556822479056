import sanitizeObject from '@htmniseko/htm-helpers/misc/sanitizeObject';
import { getLocaleCookie } from 'utils/cookies/getCookie';
import { getMessage } from 'utils/errors';
import { canUseDOM } from 'utils/misc';

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const sanitizedAction = sanitizeObject(action);
    const { promise, types, ...rest } = sanitizedAction;
    // Prevent API request from being executed on the Redux context when running on the server side
    if (!promise || !canUseDOM()) {
      return next({ types, type: '', ...rest });
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    const { auth } = getState();

    // Set the session access token in the API request, if available.
    client.setToken(auth ? auth.token : null);

    const locale = getLocaleCookie();
    // Set the locale in the API request
    client.setLocale(locale);

    const actionPromise = promise(client);
    actionPromise
      .then(
        result => next({ ...rest, result, type: SUCCESS }),
        error => next({ ...rest, error: getMessage(error), type: FAILURE })
      )
      .catch(error => {
        console.error('MIDDLEWARE ERROR:', error);
        next({ ...rest, error: getMessage(error), type: FAILURE });
      });

    return actionPromise;
  };
}
