import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const SignUpForm = loadable(() => import(/* webpackChunkName: "SignUpForm" */ './SignUpForm'), {
  fallback: <Loader visible />,
});

const AsyncSignUpForm = () => (
  <ErrorBoundary>
    <SignUpForm />
  </ErrorBoundary>
);

export default AsyncSignUpForm;
