const types = {
  ADD_GUEST: 'app/booking/ADD_GUEST',
  ADD_GUEST_SUCCESS: 'app/booking/ADD_GUEST_SUCCESS',
  ADD_GUEST_FAIL: 'app/booking/ADD_GUEST_FAIL',
  UPDATE_GUEST: 'app/booking/UPDATE_GUEST',
  UPDATE_GUEST_SUCCESS: 'app/booking/UPDATE_GUEST_SUCCESS',
  UPDATE_GUEST_FAIL: 'app/booking/UPDATE_GUEST_FAIL',
  REMOVE_GUEST: 'app/booking/REMOVE_GUEST',
  REMOVE_GUEST_SUCCESS: 'app/booking/REMOVE_GUEST_SUCCESS',
  REMOVE_GUEST_FAIL: 'app/booking/REMOVE_GUEST_FAIL',
  REMOVE_BOOKER: 'app/booking/REMOVE_BOOKER',
  REMOVE_BOOKER_SUCCESS: 'app/booking/REMOVE_BOOKER_SUCCESS',
  REMOVE_BOOKER_FAIL: 'app/booking/REMOVE_BOOKER_FAIL',
  LOAD: 'app/booking/LOAD',
  LOAD_SUCCESS: 'app/booking/LOAD_SUCCESS',
  LOAD_SUCCESS_SESSION_STORAGE: 'app/booking/LOAD_SUCCESS_SESSION_STORAGE',
  LOAD_FAIL: 'app/booking/LOAD_FAIL',
  LOAD_ONE: 'app/booking/LOAD_ONE',
  LOAD_ONE_SUCCESS: 'app/booking/LOAD_ONE_SUCCESS',
  LOAD_ONE_FAIL: 'app/booking/LOAD_ONE_FAIL',
  CLEAN_UP: 'app/booking/CLEAN_UP',
  // Update Bedding Configuration, Arrival/Departure
  UPDATE_FOLIO: 'app/booking/UPDATE_FOLIO',
  UPDATE_FOLIO_SUCCESS: 'app/booking/UPDATE_FOLIO_SUCCESS',
  UPDATE_FOLIO_FAIL: 'app/booking/UPDATE_FOLIO_FAIL',
  PATCH_BOOKING: 'app/booking/PATCH_BOOKING',
};

export default types;
