import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadBookings } from 'state/modules/booking';

const useBookings = () => {
  const dispatch = useDispatch();
  const { bookings, error, loaded, loading } = useSelector(state => state.booking);

  if (!loaded && !loading && !error) {
    dispatch(loadBookings());
  }

  return useMemo(() => ({ bookings, error, loaded, loading }), [bookings, error, loaded, loading]);
};

export default useBookings;
