import types from './types';

const { RESET, SEND, SEND_SUCCESS, SEND_FAIL } = types;

export const initialState = {
  sending: false,
  sent: false,
  error: null,
  topics: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        sending: false,
        sent: false,
        error: null,
      };
    case SEND:
      return {
        ...state,
        sending: true,
        sent: false,
        error: null,
      };
    case SEND_SUCCESS:
      return {
        ...state,
        sending: false,
        sent: true,
        error: null,
      };
    case SEND_FAIL:
      return {
        ...state,
        sending: false,
        sent: false,
        error: action.error,
      };
    default:
      return state;
  }
}
