function isWebpackProductionMode() {
  return process.env.NODE_ENV === 'production';
}

function isStaging() {
  return process.env.STAGING === 'true';
}

// The monitoring tools are initialized outside React's hydration
// At this stage, NODE_ENV takes the initial value set by the Webpack compilation mode (production when not running in dev-mode)
// So we use the STAGING variable to check if the app is running on the test environment
export function isProduction() {
  return isWebpackProductionMode() && !isStaging();
}

export function isTest() {
  return process.env.NODE_ENV === 'test' || (isWebpackProductionMode() && isStaging());
}

export function getEnvironment() {
  if (isProduction()) {
    return 'production';
  }
  if (isStaging()) {
    return 'test';
  }
  return 'development';
}
