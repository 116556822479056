import types from './types';

const { LOAD_COUNTRIES, LOAD_COUNTRIES_SUCCESS, LOAD_COUNTRIES_FAIL } = types;

export const initialState = {
  countries: [],
  loading: false,
  loaded: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_COUNTRIES: {
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
      };
    }
    case LOAD_COUNTRIES_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        countries: action.result,
      };
    case LOAD_COUNTRIES_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
