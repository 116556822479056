export function getTenantUrl(tenantUrls, url, locale) {
  // If the url is missing or the locale is missing log an error and fallback to ''
  if (!Object.keys(tenantUrls).includes(url) || !tenantUrls[url][locale]) {
    throw new Error(`Missing ${url} url in tenant config!`);
  }
  return tenantUrls[url][locale];
}

export function canUseDOM() {
  return typeof window !== 'undefined' && window.document && window.document.createElement;
}
