import moment from 'moment';
import isAlphanumeric from '@htmniseko/htm-validators/utils/isAlphanumeric';
import { maxFileUploadMb, maxFileUploadMbLabel } from 'appConfig';
import { nullifyFalsyKeys } from './objects';
import { decryptText, encryptImage, encryptObject } from './encrypt';
import { getPassportImageFilename } from './passport';

const MEGA_BYTE = 1048576;

const BASIC_PASSPORT_CONFIG = {
  minWidth: 450,
  minHeight: 350,
  maxWidth: 8000,
  maxHeight: 6500,
  maxSize: maxFileUploadMb * MEGA_BYTE,
  maxSizeLabel: maxFileUploadMbLabel * MEGA_BYTE,
  supportedFormats: ['image/jpeg', 'image/jpg'],
  supportedFormatString: 'jpeg, jpg',
};

// Check if the ID is a CMS ID
export function isCMSId(id) {
  // CMS ID is mainly numbers. Where mongoId is a mixture of both.
  if (typeof id !== 'string' && typeof id !== 'number') {
    return false;
  }
  return !Number.isNaN(Number(id));
}

function getPassportReceivedDate(guestOrProfile) {
  const receivedOn = guestOrProfile?.passportReceivedOn;
  return moment(receivedOn, 'DD/MM/YYYY').format('DD MMM YYYY');
}

export function getCmsGuestPassportStatus(cmsGuestId, booking) {
  const guest = booking?.guests?.find(cmsGuest => cmsGuest.id === cmsGuestId);
  if (!guest) {
    return { status: '' };
  }

  return { status: guest.passportScanVerified ?? '', receivedOn: getPassportReceivedDate(guest) };
}

export function getGuestTemplatePassportStatus(guestTemplate) {
  return { status: guestTemplate?.passportScanVerified ?? '', receivedOn: getPassportReceivedDate(guestTemplate) };
}

export function validatePassportImage(imageFile, { width, height }) {
  if (!imageFile) {
    return null;
  }

  const { maxWidth, maxHeight, minWidth, minHeight, maxSize, supportedFormats, supportedFormatString, maxSizeLabel } = BASIC_PASSPORT_CONFIG;
  const { size, type } = imageFile;

  if (size > maxSize) {
    return { message: 'validation.passport.invalid.size', value: `${maxSizeLabel / MEGA_BYTE}` };
  }

  if (width > maxWidth || height > maxHeight) {
    return { message: 'validation.passport.invalid.dimension.max', maxWidth, maxHeight, originalWidth: width, originalHeight: height };
  }

  if (width < minWidth || height < minHeight) {
    return { message: 'validation.passport.invalid.dimension.min', minWidth, minHeight, originalWidth: width, originalHeight: height };
  }

  if (!supportedFormats.includes(type)) {
    return { message: 'validation.passport.invalid.format', value: supportedFormatString };
  }

  return null;
}

/**
 * Parses and encrypts the Guest data to be sent in a guest add/update API request
 * @param {object} data Guest details object coming from the Guest Form
 * @return {array} Array containing the encrypted guest object and the encrypted passport file if any
 */
export function parseGuestRequestBody(data) {
  const sanitizedData = nullifyFalsyKeys(data);
  const { passportImage, ...guest } = sanitizedData;
  const encryptedData = guest && typeof guest === 'object' ? encryptObject(guest) : {};
  let file = null;

  if (passportImage) {
    const image = {
      data: passportImage.data,
      type: passportImage.type,
      lastModified: passportImage.lastModified,
      name: getPassportImageFilename(guest),
    };
    file = encryptImage(image);
  }

  return [encryptedData, file];
}

export function decryptGuest(guest) {
  const encryptedKeys = ['passport', 'expiryDate'];
  const decGuest = { ...guest };

  encryptedKeys.forEach(encKey => {
    const encValue = decGuest[encKey];
    const decValue = decryptText(encValue);
    decGuest[encKey] = decValue;
  });

  return decGuest;
}

export const isInteger = value => Number.isInteger(Number(value));

export const normalizeOnlyNumber = (value, previousValue) => (isInteger(value) ? value : previousValue);

export const normalizeAddress = (value, previousValue) => (
  isAlphanumeric(value, { allowBrackets: true, allowJapaneseCharacters: true, specialCharacters: '\b/#' }) || value === '' ? value : previousValue
);
