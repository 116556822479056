import superagent from 'superagent/dist/superagent.min';
import { nullToEmptyString } from 'utils/api';
import transform from './ApiQueryTransformer';

const methods = ['get', 'post', 'put', 'patch', 'delete'];
export const FILE_FIELD_NAME = 'file';

function formatUrl(baseUrl, path) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  // Prepend `/api` to relative URL, to proxy to API server.
  return `${baseUrl}/api/v0${adjustedPath}`;
}

export default class ApiClient {
  constructor({ baseUrl, locale, tenantId } = {}) {
    this.baseUrl = baseUrl || '';
    this.locale = locale;
    this.tenantId = tenantId;

    methods.forEach(method => {
      this[method] = (
        path,
        { params, data, file, timeout, token, paymentService } = {}
      ) => new Promise((resolve, reject) => {
        const request = superagent[method](formatUrl(this.baseUrl, path));

        if (params) {
          request.query(transform(params));
        }

        if (timeout) {
          request.timeout(timeout);
        }

        if (this.tenantId) {
          request.set('X-Tenant', this.tenantId);
        }

        // For request that contains a file, contentType will be automatically set.
        if (!file) {
          request.set('Content-Type', 'application/json');
        }

        if (this.locale) {
          request.set('Accept-Language', this.locale);
        }
        // There are a couple of special requests that set the token in the input object (i.e., when resetting the password)
        if (token || this.token) {
          request.set('Authorization', token || this.token);
        }
        if (paymentService) {
          request.set('paymentsrv', paymentService);
        }

        // For request that contains both data and file. Data must be parsed differently as form data.
        if (file && data) {
          // Loop through each key and set the field value.
          Object.keys(data).forEach(key => {
            const value = data?.[key];
            // If the value is null, superagent will ignore the field. This will cause field clearing to be impossible.
            request.field(key, nullToEmptyString(value));
          });
          request.attach(FILE_FIELD_NAME, file);
        }

        if (data && !file) {
          request.send(data);
        }

        request.end((err, { body } = {}) => (err ? reject(body || err) : resolve(body)));
      });
    });
  }

  configure({ locale, tenantId }) {
    this.locale = locale;
    this.tenantId = tenantId;
  }

  setLocale(locale) {
    this.locale = locale;
  }

  setToken(token) {
    this.token = token;
  }
}
