import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import FormattedTranslation from '@htmniseko/htm-ui/Intl/FormattedTranslationMui';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, MenuList, MenuItem, Typography } from '@material-ui/core';
import appendQueryToUrl from '@htmniseko/htm-helpers/url/appendQueryToUrl';

import usePushBooking from 'hooks/router/usePushBooking';
import usePushContact from 'hooks/router/usePushContact';
import usePushProfile from 'hooks/router/usePushProfile';
import { getCurrentPath } from 'utils/router';
import { sourceId } from 'appConfig';

const useStyles = makeStyles(theme => (
  {
    box: {
      top: theme.spacing(3), // Matches the pt={3} in the box
      position: 'sticky',
      height: 'fit-content',
    },
    container: {
      display: 'block',
      height: 'fit-content',
      width: '250px',
    },
    url: {
      fontFamily: theme.custom.romanFontFamily,
    },
  }
));

export const Menu = ({ logout, tenantUrls }) => {
  const location = useLocation();
  const classes = useStyles();
  const pushBooking = usePushBooking();
  const pushContact = usePushContact();
  const pushProfile = usePushProfile();
  const currentPath = getCurrentPath(location, 'bookings');

  const friendlyWebsiteUrl = () => {
    const urlParts = tenantUrls.website.split('.');
    return `${urlParts[1]}.${urlParts[2]}`;
  };
  const storefrontUrlWithSource = appendQueryToUrl(tenantUrls.storefront, { src: sourceId });

  return (
    <Box pt={3} className={classes.box}>
      <Container className={classes.container}>
        <Box>
          <Typography variant="h6">
            <FormattedTranslation id="navigation.desktop.label.myPortal" />
          </Typography>
          <MenuList disablePadding>
            <MenuItem
              disableRipple
              selected={currentPath === 'bookings'}
              onClick={() => pushBooking()}
            >
              <FormattedTranslation id="navigation.desktop.label.bookings" />
            </MenuItem>
            <MenuItem
              disableRipple
              selected={currentPath === 'profile'}
              onClick={pushProfile}
            >
              <FormattedTranslation id="navigation.desktop.label.profile" />
            </MenuItem>
            <MenuItem
              disableRipple
              selected={currentPath === 'contact'}
              onClick={pushContact}
            >
              <FormattedTranslation id="navigation.desktop.label.contact" />
            </MenuItem>
          </MenuList>
        </Box>
        <Box mt={5}>
          <Typography variant="h6" className={classes.url}>
            {friendlyWebsiteUrl()}
          </Typography>
          <MenuList disablePadding>
            <MenuItem component="a" disableRipple href={tenantUrls.website}>
              <FormattedTranslation id="navigation.desktop.label.home" />
            </MenuItem>
            <MenuItem component="a" disableRipple href={storefrontUrlWithSource}>
              <FormattedTranslation id="navigation.desktop.label.search" />
            </MenuItem>
            <MenuItem disableRipple onClick={logout}>
              <FormattedTranslation id="navigation.desktop.label.logout" />
            </MenuItem>
          </MenuList>
        </Box>
      </Container>
    </Box>
  );
};

Menu.propTypes = {
  tenantUrls: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default Menu;
