import usePush from './usePush';
import useCurrentBooking from '../bookings/useCurrentBooking';

const CONTACT_PATH = '/contact';

const usePushContact = () => {
  const { booking } = useCurrentBooking();
  const pushPath = usePush();

  const pushBooking = () => {
    const path = booking ? `${CONTACT_PATH}?folioId=${booking.id}` : CONTACT_PATH;
    pushPath(path);
  };
  return pushBooking;
};

export default usePushContact;
