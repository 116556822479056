import { getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem } from './webStorage';

/*
  We use a flag in session storage to see if we made any updates to the bookings in lifeline.
  Initially the flag will indicate the bookings are 'dirty'.

  When loading the the bookings we check isBookingSessionDirty() below and if they are dirty we load them from the back-end.
  On loading from the back-end we use the update function below to set the flag to 'clean'. If the flag indicates clean
  on the next load, we simply get the bookings we saved in session storage. If not, we load from back-end.

  On any action that would change bookings in the back-end we use setBookingsDirty() to set the flag to 'dirty'.
*/

const SESSION_STORAGE_BOOKINGS_KEY = 'bookings';
const SESSION_STORAGE_BOOKINGS_DIRTY_KEY = 'bookingsAreDirty';

export function restoreBookingSession() {
  return getSessionStorageItem(SESSION_STORAGE_BOOKINGS_KEY);
}

export function updateBookingSession(bookings) {
  setSessionStorageItem(SESSION_STORAGE_BOOKINGS_DIRTY_KEY, false);
  setSessionStorageItem(SESSION_STORAGE_BOOKINGS_KEY, bookings);
}

export function setBookingsDirty() {
  setSessionStorageItem(SESSION_STORAGE_BOOKINGS_DIRTY_KEY, true);
}

export function isBookingSessionDirty() {
  const isDirtyFlag = getSessionStorageItem(SESSION_STORAGE_BOOKINGS_DIRTY_KEY);
  // isDirtyFlag will not exists on the first load, so check if it does. If not then it is dirty.
  const isDirty = typeof isDirtyFlag === 'boolean' ? isDirtyFlag : true;
  if (isDirty) {
    // The session data should be reloaded
    return true;
  }
  // Check if there are bookings in the session
  const sessionBookings = getSessionStorageItem(SESSION_STORAGE_BOOKINGS_KEY);
  if (!sessionBookings) {
    // No bookings where found in the session storage
    return true;
  }
  // Clean bookings data is stored in the session storage
  return false;
}

export function clearBookingSession() {
  removeSessionStorageItem(SESSION_STORAGE_BOOKINGS_DIRTY_KEY);
  removeSessionStorageItem(SESSION_STORAGE_BOOKINGS_KEY);
}
