export default {
  auth: {
    name: 'auth',
  },
  loadBookingsFlag: {
    name: 'reloadBookings',
  },
  consent: {
    maxAgeHours: 4320, // 6 months in hours (6x30x24)
    name: 'accept',
    value: '%23alert1',
  },
  locale: {
    name: 'locale',
  },
};
