import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import BookingIcon from '@material-ui/icons/Hotel';
import GuestsIcon from '@material-ui/icons/Person';
import ContactIcon from '@material-ui/icons/ContactSupport';
import appendQueryToUrl from '@htmniseko/htm-helpers/url/appendQueryToUrl';

import usePushBooking from 'hooks/router/usePushBooking';
import usePushContact from 'hooks/router/usePushContact';
import usePushProfile from 'hooks/router/usePushProfile';
import { getCurrentPath } from 'utils/router';
import { sourceId } from 'appConfig';

const useStyles = makeStyles(theme => ({
  bottomMenu: {
    borderTop: 'solid 1px',
    borderColor: 'gray',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 50,
  },
  button: {
    color: theme.palette.text.hint,
    minWidth: 0,
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const BottomMenu = ({ logout, tenantUrls }) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const classes = useStyles();
  const pushBooking = usePushBooking();
  const pushContact = usePushContact();
  const pushProfile = usePushProfile();
  // we need to extract the root path to get current path -> /en/[root]/.../...
  const currentPath = getCurrentPath(location, 'bookings');

  function handleChange(event, newValue) {
    if (newValue === 'bookings') {
      pushBooking();
      return;
    }
    if (newValue === 'contact') {
      pushContact();
      return;
    }
    if (newValue === 'profile') {
      pushProfile();
      return;
    }
    // Handle logout
    if (newValue === 'logout') {
      logout();
    }
  }

  const storefrontUrlWithSource = appendQueryToUrl(tenantUrls.storefront, { src: sourceId });

  return (
    <BottomNavigation
      showLabels
      className={classes.bottomMenu}
      value={currentPath}
      onChange={(event, newValue) => handleChange(event, newValue)}
    >
      <BottomNavigationAction
        label={formatMessage({ id: 'navigation.mobile.label.logout' })}
        value="logout"
        icon={<LogoutIcon />}
        classes={{ root: classes.button, label: classes.label }}
      />
      <BottomNavigationAction
        label={formatMessage({ id: 'navigation.mobile.label.search' })}
        value=""
        icon={<SearchIcon />}
        component="a"
        href={storefrontUrlWithSource}
        classes={{ root: classes.button, label: classes.label }}
      />
      <BottomNavigationAction
        label={formatMessage({ id: 'navigation.mobile.label.bookings' })}
        value="bookings"
        icon={<BookingIcon />}
        classes={{ root: classes.button, label: classes.label }}
      />
      <BottomNavigationAction
        label={formatMessage({ id: 'navigation.mobile.label.profile' })}
        value="profile"
        icon={<GuestsIcon />}
        classes={{ root: classes.button, label: classes.label }}
      />
      <BottomNavigationAction
        label={formatMessage({ id: 'navigation.mobile.label.contact' })}
        value="contact"
        icon={<ContactIcon />}
        classes={{ root: classes.button, label: classes.label }}
      />
    </BottomNavigation>
  );
};

BottomMenu.propTypes = {
  tenantUrls: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default BottomMenu;
