/*
  Global configurations, applies for all tenants
*/
module.exports = {
  name: 'HTM Guest Portal',
  appCode: 'guest',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 8880,
  api: {
    host: process.env.APIHOST || 'http://localhost:3335',
  },
  imageUrl: process.env.NODE_ENV === 'production' ? 'https://htm-storefront.s3.amazonaws.com' : 'https://htm-storefront.htmniseko.com',
  sourceId: 'gp',
  passportVerificationStatus: {
    VERIFIED: true,
    VERIFIED_FAILED: false,
    UPLOADED: null,
    NOT_UPLOADED: 'NOT_UPLOADED',
  },
  // We allow a bit of extra space for the file upload to allow uploads from guest that are slightly larger than 8mb
  maxFileUploadMb: 8.2,
  maxFileUploadMbLabel: 8,
  // Sever request timeout in milliseconds (it can be useful for cases like the passport image upload)
  serverTimeout: 120000,
};
