import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const NotFound = loadable(() => import(/* webpackChunkName: "NotFound" */ './NotFound'), {
  fallback: <Loader visible />,
});

const AsyncNotFound = () => (
  <ErrorBoundary>
    <NotFound />
  </ErrorBoundary>
);

export default AsyncNotFound;
