import CryptoJS from 'crypto-js';

function getSecretPhrase() {
  // On the client-side, the encryption secret is stored in Base64 format
  return window ? window.atob(process.env.ENCRYPT_SECRET) : process.env.ENCRYPT_SECRET;
}

function getKey() {
  return CryptoJS.enc.Utf8.parse(process.env.ENCRYPT_KEY || '');
}

function getIv() {
  return CryptoJS.enc.Utf8.parse(process.env.ENCRYPT_IV || '');
}

function getConfig() {
  return {
    keySize: 128 / 8,
    iv: getIv(),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };
}

export function encryptImage(image) {
  const { data, lastModified, name, type } = image;
  const encrypted = CryptoJS.AES.encrypt(data, getSecretPhrase()).toString();
  const encryptedImage = new File([encrypted], name, { type, lastModified });
  return encryptedImage;
}

export function encryptText(text) {
  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text.toString()), getKey(), getConfig()).toString();
}

export function encryptObject(obj) {
  const encrypted = {};
  const encryptFn = value => (typeof value === 'object' ? encryptObject(value) : encryptText(value));

  if (Array.isArray(obj)) {
    return obj.map(encryptFn);
  }

  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      encrypted[key] = encryptFn(obj[key]);
    }
  });
  return encrypted;
}

export function decryptText(text) {
  if (text === null || text === undefined) {
    return undefined;
  }
  return CryptoJS.AES.decrypt(text, getKey(), getConfig()).toString(CryptoJS.enc.Utf8);
}

export function decryptObject(obj) {
  if (obj === null || obj === undefined) {
    return obj;
  }

  const encrypted = {};
  const decryptFn = value => (typeof value === 'object' ? decryptObject(value) : decryptText(value));

  if (Array.isArray(obj)) {
    return obj.map(decryptFn);
  }

  Object.keys(obj).forEach(key => {
    encrypted[key] = decryptFn(`${obj[key]}`);
  });
  return encrypted;
}
