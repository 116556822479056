import React from 'react';
import loadable from '@loadable/component';
import { MatchOne, Tenant } from 'containers/Tenancy';

const KNHeader = loadable(() => import('../@kiniseko/Header'));
const NCHeader = loadable(() => import('../@nisekocentral/Header'));
const SNHeader = loadable(() => import('../@skye/Header'));

const Header = () => (
  <MatchOne>
    <Tenant id="kiniseko">
      <KNHeader />
    </Tenant>
    <Tenant id="nisekocentral">
      <NCHeader />
    </Tenant>
    <Tenant id="skye">
      <SNHeader />
    </Tenant>
  </MatchOne>
);

export default Header;
