import isString from 'lodash/isString';
import types from './types';

const {
  CLEAN_UP, CLEAR_ERROR, CLEAN_SIGN_UP, CLEAN_UPDATE,
  SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL,
  LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT,
  SSO_CALLBACK, SSO_INIT, SSO_SIGNUP, SSO_FAIL, SSO_LOGIN,
  REQUEST, REQUEST_SUCCESS, REQUEST_FAIL,
  RESET, RESET_SUCCESS, RESET_FAIL, RESET_EMAIL_SUCCESS, RESET_PASSWORD_SUCCESS,
  RESTORE_LOGIN,
  SEND_VERIFICATION_EMAIL, SEND_VERIFICATION_EMAIL_SUCCESS, SEND_VERIFICATION_EMAIL_FAIL,
  VERIFY, VERIFY_SUCCESS, VERIFY_FAIL,
  VERIFY_TOKEN, VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_FAIL,
  UPDATE_STATUS_RESET,
  SEND_LOGIN_LINK, SEND_LOGIN_LINK_SUCCESS, SEND_LOGIN_LINK_FAIL,
  RESET_LINK_STATE, SET_REDIRECTED,
  SSO_CALLBACK_SUCCESS,
} = types;

export const initialState = {
  authenticated: false,
  signingUp: false,
  signedUp: false, // signedUp should be true only right after completing a using sign up action
  verifying: false,
  verified: false,
  verifyingToken: false,
  verifiedToken: false,
  resetting: false,
  reset: false,
  requested: false,
  requesting: false,
  sendingVerificationEmail: false,
  sentVerificationEmail: false,
  loggingIn: false,
  token: null,
  redirected: false,
  ssoCallbackURL: null,
  ssoProfile: null,
  update: false,
  updating: false,
  updated: false,
  user: null,
  email: null, // Used for setting the default value in login input fields
  error: null,
  linkError: null,
  session: null,
};

export default function reducer(state = initialState, action = {}) {
  const token = action?.result?.id;
  const user = action?.result?.user;
  const emailStatus = action?.result?.emailStatus ?? 'failed';
  const authenticated = isString(token);

  switch (action.type) {
    case VERIFY:
      return {
        ...state,
        verifying: true,
        verified: false,
        error: null,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        user: action.result.user,
        verifying: false,
        verified: true,
      };
    case VERIFY_FAIL:
      return {
        ...state,
        verifying: false,
        error: action.error,
      };
    case VERIFY_TOKEN:
      return {
        ...state,
        verifyingToken: true,
        verifiedToken: false,
      };
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        authenticated: action.result,
        verifyingToken: false,
        verifiedToken: action.result,
      };
    case VERIFY_TOKEN_FAIL:
      return {
        ...state,
        authenticated: false,
        verifyingToken: false,
        verifiedToken: false,
      };
    case SIGNUP:
      return {
        ...state,
        signingUp: true,
        signedUp: false,
        user: null,
        error: null,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        authenticated,
        signingUp: false,
        signedUp: true,
        token,
        user,
        verificationEmailSent: emailStatus === 'success',
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        signingUp: false,
        error: action.error,
      };
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        token: null,
        user: null,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated,
        // We can safely say that the token is verified (it has just been issued)
        verifiedToken: authenticated,
        loggingIn: false,
        token,
        user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        error: action.error,
      };
    case LOGOUT: {
      action.logout();
      return { ...state };
    }
    case REQUEST:
      return {
        ...state,
        requested: false,
        error: null,
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        requested: true,
      };
    case REQUEST_FAIL:
      return {
        ...state,
        requested: false,
        error: action.error,
      };
    case RESET:
      return {
        ...state,
        resetting: true,
        reset: false,
        error: null,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        resetting: false,
        reset: true,
      };
    case RESET_FAIL:
      return {
        ...state,
        resetting: false,
        error: action.error,
      };
    case RESET_EMAIL_SUCCESS:
      return {
        ...state,
        resetting: false,
        reset: true,
        token: null,
        user: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetting: false,
        reset: true,
        token: action.result.token,
        user: { ...state.user, isPasswordSet: true },
      };
    case RESTORE_LOGIN:
      return {
        ...state,
        token,
        user,
        authenticated: true,
      };
    case SSO_SIGNUP:
      return {
        ...state,
        signingUp: false,
        signedUp: false,
        ssoProfile: null,
        user: null,
        error: null,
      };
    case SSO_LOGIN:
      return {
        ...state,
        loggingIn: false,
        token: null,
        user: null,
        error: null,
      };
    case SSO_CALLBACK:
      return {
        ...state,
        signingUp: true,
        loggingIn: true,
        token: null,
        user: null,
        error: null,
      };
    case SSO_INIT:
      return {
        ...state,
        ssoCallbackURL: action.result.location,
      };
    case SSO_CALLBACK_SUCCESS:
      return {
        ...state,
        authenticated,
        // We can safely say that the token is verified (it has just been issued)
        verifiedToken: authenticated,
        signingUp: false,
        loading: false,
        loggingIn: false,
        ssoProfile: action.result.sso,
        token,
        user,
      };
    case SSO_FAIL:
      return {
        ...state,
        loading: false,
        loggingIn: false,
        signingUp: false,
        signedUp: false,
        user: null,
        ssoProfile: null,
        error: action.error,
      };
    case UPDATE_STATUS_RESET:
      return {
        ...state,
        update: false,
        updating: false,
        updated: false,
      };
    case CLEAN_UP:
      return { ...initialState };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAN_SIGN_UP:
      return {
        ...state,
        signedUp: false,
        signingUp: false,
      };
    case CLEAN_UPDATE:
      return {
        ...state,
        update: false,
        updating: false,
        updated: false,
      };
    case SEND_LOGIN_LINK:
      return {
        ...state,
        requested: false,
        requesting: true,
        error: null,
      };
    case SEND_LOGIN_LINK_SUCCESS:
      return {
        ...state,
        requested: true,
        requesting: false,
      };
    case SEND_LOGIN_LINK_FAIL:
      return {
        ...state,
        requested: false,
        requesting: false,
        error: action.error,
      };
    case SEND_VERIFICATION_EMAIL:
      return {
        ...state,
        sendingVerificationEmail: true,
        sentVerificationEmail: false,
        error: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendingVerificationEmail: false,
        sentVerificationEmail: true,
      };
    case SEND_VERIFICATION_EMAIL_FAIL:
      return {
        ...state,
        sendingVerificationEmail: false,
        sentVerificationEmail: false,
        error: action.error,
      };
    case RESET_LINK_STATE:
      return {
        ...state,
        requested: false,
        requesting: false,
        error: null,
      };
    case SET_REDIRECTED:
      return {
        ...state,
        redirected: true,
      };
    default:
      return state;
  }
}
