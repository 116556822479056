import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import moment from 'moment';
import { Provider } from 'react-redux';
import { shouldPolyfill } from '@formatjs/intl-listformat/should-polyfill';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { restoreLogin } from 'state/modules/auth/actions';
import configureStore from 'state/create';
import App from 'facades/App';
import ApiClient from 'helpers/ApiClient';
import { momentDateFormats } from 'utils/moment';
import { startDatadogLogs, startDatadogRum } from 'utils/monitoring/datadog';

startDatadogRum();
startDatadogLogs();

// Update date display format for both locales
moment.updateLocale('en', momentDateFormats.en);
moment.updateLocale('ja', momentDateFormats.ja);

// Set up all the initial stuff
const pathName = window?.location?.pathname ?? '';
const locale = pathName.startsWith('/ja') ? 'ja' : 'en';
moment.locale(locale);

// Get the initial data from server-side rendering
const initialState = window.__INITIAL_STATE__;
const messages = window.__MESSAGES__;

const client = new ApiClient();
client.configure({ locale, tenantId: initialState.tenant.id });
const { store } = configureStore({ initialState, client });

// Retrieve user authentication from sessionStorage
if (window.sessionStorage && window.sessionStorage.getItem('auth') && true) {
  const authObject = JSON.parse(window.sessionStorage.getItem('auth'));
  store.dispatch(restoreLogin(authObject));
}

// Polyfill the formatList API if the current user's browser doesn't support it
if (shouldPolyfill(locale)) {
  // eslint-disable-next-line global-require
  require('@formatjs/intl-listformat/polyfill-force');
  // eslint-disable-next-line
  require(`@formatjs/intl-listformat/locale-data/${locale}`);
}

// Check if PluralRules is available, old browsers IE11, Chrome v.<=68 and Safari doesn't support it
if (!Intl.PluralRules) {
  // Enable the PluralRules polyfill
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/polyfill');
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/locale-data/en');
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/locale-data/ja');
}

function render() {
  ReactDOM.hydrate(
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={createTheme(initialState.tenant.thememui)}>
          <AppContainer>
            <App locale={locale} messages={messages} />
          </AppContainer>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>,
    document.getElementById('appRoot')
  );
}

// loadable-component setup
loadableReady(() => {
  render();
});

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('facades/App', () => {
    // eslint-disable-next-line global-require
    const HotApp = require('facades/App').default;
    render(HotApp);
  });
}
