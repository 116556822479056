import { combineReducers } from 'redux';

import auth from './auth/reducers';
import booking from './booking/reducers';
import country from './country/reducers';
import dialog from './dialog/reducers';
import form from './form';
import guest from './guest/reducers';
import tenant from './tenant/reducers';
import contact from './contact/reducers';
import payment from './payment/reducers';
import email from './email/reducers';
import phone from './phone/reducers';

const reducers = {
  form,

  payment,
  auth,
  booking,
  country,
  dialog,
  guest,
  tenant,
  contact,
  email,
  phone,
};

const createReducer = () => combineReducers({ ...reducers });

export default createReducer;
