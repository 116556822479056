import config from 'appConfig';
import { clearBookingSession } from 'utils/bookingSession';
import { deleteAuthCookie } from 'utils/cookies/deleteCookie';
import { currentUrl } from 'utils/router';
import types from './types';

const {
  CLEAN_UP, CLEAR_ERROR, CLEAN_SIGN_UP, CLEAN_UPDATE,
  SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL,
  LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT,
  SSO_CALLBACK, SSO_INIT, SSO_SIGNUP, SSO_FAIL, SSO_LOGIN,
  REQUEST, REQUEST_SUCCESS, REQUEST_FAIL,
  SEND_VERIFICATION_EMAIL, SEND_VERIFICATION_EMAIL_SUCCESS, SEND_VERIFICATION_EMAIL_FAIL,
  SEND_LOGIN_LINK, SEND_LOGIN_LINK_SUCCESS, SEND_LOGIN_LINK_FAIL,
  RESET_LINK_STATE,
  RESET, RESET_SUCCESS, RESET_FAIL, RESET_PASSWORD_SUCCESS,
  RESTORE_LOGIN,
  VERIFY, VERIFY_SUCCESS, VERIFY_FAIL,
  VERIFY_TOKEN, VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_FAIL,
  UPDATE_STATUS_RESET, SET_REDIRECTED, SESSION_REFRESH,
  SSO_CALLBACK_SUCCESS,
} = types;

const SSO_PORTAL = 'guestPortal';
const portal = config.appCode;

function getSsoParams(code = '', keepSession = false) {
  const params = { portal: SSO_PORTAL, url: currentUrl(), keepSession };

  if (typeof code === 'string' && code.length > 0) {
    params.code = code;
  }

  return params;
}

export function cleanup() {
  return { type: CLEAN_UP };
}

export function cleanSignUp() {
  return { type: CLEAN_SIGN_UP };
}

export function cleanUpdate() {
  return { type: CLEAN_UPDATE };
}

export function clearError() {
  return { type: CLEAR_ERROR };
}

export function verify(uid, token) {
  return {
    types: [VERIFY, VERIFY_SUCCESS, VERIFY_FAIL],
    promise: client => client.get('/GuestAccounts/confirm', { params: { uid, token } }),
  };
}

export function verifyToken(token) {
  return {
    types: [VERIFY_TOKEN, VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_FAIL],
    promise: client => client.get('/auth/verifyToken', { params: { portal }, token }),
  };
}

export function signUp(user) {
  return {
    types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
    promise: client => client.post('/GuestAccounts', { data: user }),
  };
}

export function loginWithSSO(provider) {
  const params = getSsoParams();
  return {
    types: [SSO_LOGIN, SSO_INIT, SSO_FAIL],
    sso: provider,
    promise: client => client.get(`/auth/${provider}`, { params }),
  };
}

export function loginWithSSOCallback(provider, code, keepSession) {
  const params = getSsoParams(code, keepSession);
  return {
    types: [SSO_CALLBACK, SSO_CALLBACK_SUCCESS, SSO_FAIL],
    sso: provider,
    promise: client => client.get(`/auth/${provider}/callback`, { params }),
  };
}

export function signUpWithSSO(provider) {
  const params = getSsoParams();
  return {
    types: [SSO_SIGNUP, SSO_INIT, SSO_FAIL],
    sso: provider,
    promise: client => client.get(`/auth/${provider}`, { params }),
  };
}

export function signUpWithSSOCallback(provider, code) {
  const params = getSsoParams(code);
  return {
    types: [SSO_CALLBACK, SSO_CALLBACK_SUCCESS, SSO_FAIL],
    sso: provider,
    promise: client => client.get(`/auth/${provider}/callback`, { params }),
  };
}

export function login(username, password, keepSession) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: client => client.post('/auth/guestPortal/login', { data: { username, password, keepSession } }),
  };
}

export function logout() {
  // Clear the booking session so the bookings don't show up if another user logs in to the same session
  return {
    type: LOGOUT,
    logout: () => {
      clearBookingSession();
      deleteAuthCookie();
      window.location.assign(`${window.location.origin}/logout`);
    },
  };
}

export function forgot(email) {
  return {
    types: [REQUEST, REQUEST_SUCCESS, REQUEST_FAIL],
    promise: client => client.post('/GuestAccounts/forgot', { data: { email } }),
  };
}

export function reset(password, token) {
  return {
    types: [RESET, RESET_SUCCESS, RESET_FAIL],
    promise: client => client.post('/GuestAccounts/reset-password', { token, data: { password } }),
  };
}

export function sendEmailVerification(email) {
  return {
    types: [SEND_VERIFICATION_EMAIL, SEND_VERIFICATION_EMAIL_SUCCESS, SEND_VERIFICATION_EMAIL_FAIL],
    promise: client => client.post('/GuestAccounts/sendVerificationEmail', { data: { email } }),
  };
}

export function changePassword(oldPassword, newPassword) {
  return {
    types: [RESET, RESET_PASSWORD_SUCCESS, RESET_FAIL],
    promise: client => client.post('/GuestAccounts/change-password', { data: { oldPassword, newPassword } }),
  };
}

export function setPassword(password) {
  return {
    types: [RESET, RESET_PASSWORD_SUCCESS, RESET_FAIL],
    promise: client => client.post('/GuestAccounts/set-own-password', { data: { password } }),
  };
}

export function resetUpdateStatus() {
  return { type: UPDATE_STATUS_RESET };
}

export function restoreLogin(authState) {
  const { token, user } = authState;
  return {
    type: RESTORE_LOGIN,
    token,
    user,
  };
}

export function sendLoginLink(email, keepSession, redirectTo = '') {
  return {
    types: [SEND_LOGIN_LINK, SEND_LOGIN_LINK_SUCCESS, SEND_LOGIN_LINK_FAIL],
    promise: client => client.post('/GuestAccounts/sendLoginLink', { data: { email, redirectTo, keepSession } }),
  };
}

export function clearLinkState() {
  return {
    type: RESET_LINK_STATE,
  };
}

export function setRedirected() {
  return {
    type: SET_REDIRECTED,
  };
}

export function refresh() {
  return {
    type: SESSION_REFRESH,
  };
}
