import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const Payment = loadable(() => import(/* webpackChunkName: "Payment" */ './Payment'), {
  fallback: <Loader />,
});

const asyncPayment = () => (
  <ErrorBoundary>
    <Payment />
  </ErrorBoundary>
);

export default asyncPayment;
