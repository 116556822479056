import { canUseDOM } from 'utils/misc';
import cookiesParams from './cookiesParams';

function getCookie(name) {
  if (!canUseDOM()) {
    return undefined;
  }

  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`))
    ?.split('=')[1];

  return cookieValue;
}

export function getAcceptCookie() {
  return getCookie(cookiesParams.consent.name);
}

export function getReloadBookingCookie() {
  return getCookie(cookiesParams.loadBookingsFlag.name);
}

export function getLocaleCookie() {
  return getCookie(cookiesParams.locale.name);
}
