import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from './Dialog';

const DialogsContainer = () => {
  const dialogs = useSelector(state => state.dialog.dialogs);
  return (
    <>
      {
        /* We now support many dialogs to be rendered simultaneously
           which we handle as a stack of dialogs to be rendered one of top of each other
           the zIndex prop allows this to be done
        */
      }
      {dialogs.map(({ dialogName, dialogProps, zIndex }) => (
        <Dialog
          key={`dialog_${dialogName}`}
          dialogName={dialogName}
          dialogProps={dialogProps}
          zIndex={zIndex}
        />
      ))}
    </>
  );
};

export default DialogsContainer;
