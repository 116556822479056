import types from './types';

const {
  CLEAN_UP,
  CHECK_EMAIL, CHECK_EMAIL_SUCCESS, CHECK_EMAIL_FAIL,
} = types;

const initialState = {
  checked: false,
  exists: false,
  checking: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAN_UP:
      return initialState;
    case CHECK_EMAIL:
      return {
        ...state,
        checked: false,
        exists: false,
        checking: true,
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checked: true,
        exists: action.result, // Successful response can return false
        checking: false,
      };
    case CHECK_EMAIL_FAIL:
      return {
        ...state,
        checked: true,
        exists: false,
        checking: false,
      };
    default:
      return state;
  }
}
