import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ActivateAccount from 'facades/ActivateAccount';
import BookingList from 'facades/BookingList';
import Booking from 'facades/Booking';
import BookingDetails from 'containers/Booking/Details';
import BookingFinancials from 'containers/Booking/Financials';
import BookingGuests from 'containers/Booking/Guests';
import BookingServices from 'containers/Booking/Services';
import Contact from 'facades/Contact';
import ForgotPassword from 'facades/ForgotPassword';
import Login from 'facades/Login';
import NotFound from 'facades/NotFound';
import Payment from 'facades/Payment';
import Profile from 'facades/Profile';
import ResetPassword from 'facades/ResetPassword';
import SignUp from 'facades/SignUp';
import SignUpForm from 'facades/SignUpForm';
import ProtectedRoute from './ProtectedRoute';

// eslint-disable-next-line react/function-component-definition
export default () => (
  <Routes>
    <Route path="/:locale" element={<ProtectedRoute><BookingList /></ProtectedRoute>} />
    <Route path="/:locale/activate" element={<ActivateAccount />} />
    <Route path="/:locale/bookings" element={<ProtectedRoute><BookingList /></ProtectedRoute>} />
    <Route path="/:locale/bookings/:id" element={<ProtectedRoute><Booking /></ProtectedRoute>}>
      <Route path="details" element={<ProtectedRoute><BookingDetails /></ProtectedRoute>} />
      <Route path="financials" element={<ProtectedRoute><BookingFinancials /></ProtectedRoute>} />
      <Route path="guests" element={<ProtectedRoute><BookingGuests /></ProtectedRoute>} />
      <Route path="guests/:id" element={<ProtectedRoute><BookingGuests /></ProtectedRoute>} />
      <Route path="services" element={<ProtectedRoute><BookingServices /></ProtectedRoute>} />
    </Route>
    <Route path="/:locale/bookings/:id/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
    <Route path="/:locale/contact" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
    <Route path="/:locale/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
    <Route path="/:locale/forgotPassword" element={<ForgotPassword />} />
    <Route path="/:locale/login" element={<Login />} />
    <Route path="/:locale/resetPassword" element={<ResetPassword />} />
    <Route path="/:locale/signUp" element={<SignUp />} />
    <Route path="/:locale/signUpForm" element={<SignUpForm />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
