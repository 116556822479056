import PropTypes from 'prop-types';
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

const TinyMessage = ({ color, children, icon }) => (
  <Grid container direction="row" alignItems="center">
    <Grid item>
      <Box mr={0.5} color={color} fontSize="body2.fontSize">
        {
          // Set the icon size to use the fontSize from the parent box
          React.cloneElement(icon, { fontSize: 'inherit' })
        }
      </Box>
    </Grid>
    <Grid item>
      <Box color={color}>
        <Typography variant="body2">
          {children}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

TinyMessage.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node.isRequired,
};

export default TinyMessage;
