import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { canUseDOM } from 'utils/misc';

const ProtectedRoute = ({ children }) => {
  const { locale } = useIntl();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated && canUseDOM()) {
    return (
      <Navigate
        to={`/${locale}/login`}
        state={{ from: location }}
        replace
      />
    );
  }
  // Is authenticated
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
