export function getRandomString(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charLength = chars.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

export function titleCase(value) {
  if (typeof value !== 'string' || value.length === 0) {
    return '';
  }
  if (value.length === 1) {
    return value.toUpperCase();
  }
  return `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
}

// Removes white spaces and colon from end of a string (labels should not have a colon or space at the end)
export function sanitizeLabel(label) {
  // Remove spaces from end
  const noSpaces = label.trim();
  // IF string has colon at the end remove it
  if (noSpaces.endsWith(':')) {
    // Return string without last char
    return noSpaces.slice(0, noSpaces.length - 1);
  }
  // Return string without last char
  return noSpaces;
}

// Lower case and trim value
export function lowerTrim(value) {
  if (typeof value !== 'string') {
    return value;
  }
  return value.trim().toLowerCase();
}
