import { updateBooking } from 'utils/booking';
import { updateBookingSession } from 'utils/bookingSession';
import types from './types';

const {
  ADD_GUEST, ADD_GUEST_SUCCESS, ADD_GUEST_FAIL,
  UPDATE_GUEST, UPDATE_GUEST_SUCCESS, UPDATE_GUEST_FAIL,
  REMOVE_GUEST, REMOVE_GUEST_SUCCESS, REMOVE_GUEST_FAIL,
  REMOVE_BOOKER, REMOVE_BOOKER_SUCCESS, REMOVE_BOOKER_FAIL,
  LOAD, LOAD_SUCCESS, LOAD_FAIL, LOAD_SUCCESS_SESSION_STORAGE,
  LOAD_ONE, LOAD_ONE_SUCCESS, LOAD_ONE_FAIL,
  UPDATE_FOLIO, UPDATE_FOLIO_SUCCESS, UPDATE_FOLIO_FAIL,
  CLEAN_UP,
} = types;

export const initialState = {
  // Guest
  addingGuest: false,
  addedGuest: false,
  updatingGuest: false,
  updatedGuest: false,
  removingGuest: false,
  removedGuest: false,
  // Folio Update (Arrival/Departure, Bed Configuration)
  updating: false,
  updated: false,
  // Rest
  loading: false,
  loaded: false,
  loadingOne: false,
  loadedOne: false,
  bookings: null,
  passportUpload: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_GUEST:
      return {
        ...state,
        addingGuest: true,
        addedGuest: false,
        passportUpload: action.passportUpload,
        error: null,
      };
    case ADD_GUEST_SUCCESS: {
      const bookings = updateBooking(state.bookings, action.result.booking);
      updateBookingSession(bookings);
      return {
        ...state,
        bookings,
        addingGuest: false,
        addedGuest: true,
      };
    }
    case ADD_GUEST_FAIL:
      return {
        ...state,
        addingGuest: false,
        error: action.error,
      };
    case UPDATE_GUEST:
      return {
        ...state,
        updatingGuest: true,
        updatedGuest: false,
        passportUpload: action.passportUpload,
        error: null,
      };
    case UPDATE_GUEST_SUCCESS: {
      const bookings = updateBooking(state.bookings, action.result.booking);
      updateBookingSession(bookings);
      return {
        ...state,
        bookings,
        updatingGuest: false,
        updatedGuest: true,
      };
    }
    case UPDATE_GUEST_FAIL:
      return {
        ...state,
        updatingGuest: false,
        updatedGuest: false,
        error: action.error,
      };
    case REMOVE_GUEST:
      return {
        ...state,
        removingGuest: true,
        removedGuest: false,
        error: null,
      };
    case REMOVE_GUEST_SUCCESS: {
      const bookings = updateBooking(state.bookings, action.result.booking);
      updateBookingSession(bookings);
      return {
        ...state,
        bookings,
        removingGuest: false,
        removedGuest: true,
      };
    }
    case REMOVE_GUEST_FAIL:
      return {
        ...state,
        removingGuest: false,
        removedGuest: false,
        error: action.error,
      };
    case REMOVE_BOOKER:
      return {
        ...state,
        error: null,
      };
    case REMOVE_BOOKER_SUCCESS: {
      const { removeBooking, result } = action;
      const folioId = Number(result.folioId);

      const bookings = removeBooking ?
        // When removeBooking is true, the current user removed his own access and then the folio should be filtered out from the state
        state.bookings.filter(booking => booking.id !== folioId)
        // The access was removed from another user (not the current user), not filter out the booking
        : state.bookings.map(booking => {
          if (booking.id !== folioId) {
            return booking;
          }
          // It's the folio which access has been removed to the booker (set the booker remove flags as false, there's no need to show booker info again)
          return { ...booking, booker: { removeAccess: false, removeMyAccess: false } };
        });
      updateBookingSession(bookings);
      return {
        ...state,
        bookings,
      };
    }
    case REMOVE_BOOKER_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case LOAD:
      return {
        // Don't reset the current state.
        // The load response may not be instant and we don't want to clear all content while loading
        loading: true,
      };
    case LOAD_SUCCESS:
      updateBookingSession(action.result);
      return {
        ...state,
        loading: false,
        loaded: true,
        bookings: action.result,
      };
    case LOAD_SUCCESS_SESSION_STORAGE:
      return {
        ...initialState,
        loaded: true,
        bookings: action.result,
      };
    case LOAD_FAIL:
      return {
        ...initialState,
        loading: false,
        error: action.error,
      };
    case LOAD_ONE:
      return {
        ...state,
        loadingOne: true,
      };
    case LOAD_ONE_SUCCESS: {
      const bookings = updateBooking(state.bookings, action.result);
      updateBookingSession(bookings);
      return {
        ...state,
        loadingOne: false,
        loadedOne: true,
        bookings,
      };
    }
    case LOAD_ONE_FAIL:
      return {
        ...state,
        loadingOne: false,
        error: action.error,
      };
    case UPDATE_FOLIO: {
      return {
        ...state,
        updating: true,
        updated: false,
        error: null,
      };
    }
    case UPDATE_FOLIO_SUCCESS: {
      const bookings = updateBooking(state.bookings, action.result.booking);
      updateBookingSession(bookings);
      return {
        ...state,
        bookings,
        updating: false,
        updated: true,
      };
    }
    case UPDATE_FOLIO_FAIL:
      return {
        ...state,
        updating: false,
        updated: false,
        error: action.error,
      };
    case CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}
