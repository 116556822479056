import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const Booking = loadable(() => import(/* webpackChunkName: "Booking" */ './Booking'), {
  fallback: <Loader visible />,
});

const asyncBooking = () => (
  <ErrorBoundary>
    <Booking />
  </ErrorBoundary>
);

export default asyncBooking;
