import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import bookingTypes from 'utils/constants/booking/bookingTypes';
import useBookings from './useBookings';

const useCurrentBooking = () => {
  const { bookings, loaded, loading } = useBookings();
  const location = useLocation();

  return useMemo(() => {
    const upcomingBookings = bookings ? bookings[bookingTypes.upcoming] : [];
    if (!Array.isArray(upcomingBookings) || upcomingBookings.length === 0) {
      // No bookings have been loaded or not bookings have been found
      return { booking: null, loaded, loading };
    }

    // There are future bookings, return the one requested as per the URL
    const booking = upcomingBookings.find(booking => location.pathname.includes(booking.id));
    return { booking, loaded, loading };
  }, [bookings, loaded, loading, location.pathname]);
};

export default useCurrentBooking;
