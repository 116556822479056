/* eslint import/prefer-default-export: "off" */

export function getGatewayUrl() {
  const origin = window?.location?.origin;
  if (!origin) {
    return null;
  }
  // Proxy to the Gateway using own domain
  return `${origin}/api`;
}

export function nullToEmptyString(value) {
  if (value === null) {
    return '';
  }
  return value;
}
