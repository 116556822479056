import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const ResetPassword = loadable(() => import(/* webpackChunkName: "ResetPassword" */ './ResetPassword'), {
  fallback: <Loader visible />,
});

const AsyncResetPassword = () => (
  <ErrorBoundary>
    <ResetPassword />
  </ErrorBoundary>
);

export default AsyncResetPassword;
