import { reducer as formReducer } from 'redux-form';
import authTypes from './auth/types';

const { SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL } = authTypes;

const form = formReducer.plugin({
  signUp: (state, action) => {
    switch (action.type) {
      case SIGNUP:
        return {
          ...state,
          submitSucceeded: false,
        };
      case SIGNUP_SUCCESS:
        // Clear the form state
        return undefined;
      case SIGNUP_FAIL:
        return {
          ...state,
          submitFailed: true,
          submitSucceeded: false,
          values: {
            ...state.values,
            // Clear the passwords
            password: undefined,
            repeatPassword: undefined,
          },
        };
      default:
        return state;
    }
  },
});

export default form;
