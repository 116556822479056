import { loadOneBooking } from 'state/modules/booking';
import types from 'state/modules/payment/types';

export default ({ dispatch }) => next => action => {
  const { folio, type } = action;

  // If the payment success action has been triggered, load the full data of the related folio
  if (type === types.PAYMENT_SUCCESS) {
    dispatch(loadOneBooking(folio.id));
  }

  next(action);
};
