export default {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  ERROR: 'ERROR',
  GUEST_EXISTING: 'GUEST_EXISTING',
  GUEST_FORM: 'GUEST_FORM',
  GUEST_MAXIMUM: 'GUEST_MAXIMUM',
  GUEST_SUCCESS: 'GUEST_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  SUCCESS: 'SUCCESS',
};
