/* eslint-disable import/prefer-default-export */
import { name } from 'appConfig';
import types from './types';

const { REPORT, REPORT_FAIL, REPORT_SUCCESS } = types;

export function report(message, stack, componentStack) {
  const data = {
    app: name,
    message,
    stack,
    componentStack,
    referrer: window.location.href,
    url: window.location.origin,
    userAgent: window.navigator.userAgent,
  };
  return {
    types: [REPORT, REPORT_SUCCESS, REPORT_FAIL],
    promise: client => client.post('/error', { data }),
  };
}
