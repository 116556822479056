import PropTypes from 'prop-types';
import React from 'react';
import FormattedTranslation from '@htmniseko/htm-ui/Intl/FormattedTranslationMui';
import Cancel from '@material-ui/icons/Cancel';
import { Box, Button } from '@material-ui/core';
import Message from './Message';
import TinyMessage from './TinyMessage';

const icon = <Cancel />;

const ErrorMessage = ({ children, onRetry, tiny, title, gutterBottom }) => (
  tiny ? (
    <TinyMessage color="error.main" icon={icon}>
      {children}
    </TinyMessage>
  ) : (
    <Message gutterBottom={gutterBottom} titleColor="inherit" color="error.main" icon={icon} title={title}>
      <>
        <Box>
          {children}
        </Box>
        {
          typeof onRetry === 'function' && (
            <Box mt={2} textAlign="right">
              <Button variant="contained" onClick={onRetry}>
                <FormattedTranslation id="general.error.retry" />
              </Button>
            </Box>
          )
        }
      </>
    </Message>
  ));

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onRetry: PropTypes.func,
  tiny: PropTypes.bool,
  title: PropTypes.string,
  gutterBottom: PropTypes.bool,
};

ErrorMessage.defaulProps = {
  tiny: false,
  gutterBottom: false,
};

export default ErrorMessage;
