import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CookiesConsentBar } from 'components/Cookies';
import useAuth from 'hooks/useAuth';
import { setAcceptCookie } from 'utils/cookies/setCookie';
import { getAcceptCookie } from 'utils/cookies/getCookie';
import { canUseDOM } from 'utils/misc';

const CookiesConsent = () => {
  const { locale } = useIntl();
  const privacyUrl = useSelector(state => state.tenant?.urls?.privacy ?? {});
  const { isAuthenticated } = useAuth();
  const [visible, setVisible] = useState(true);

  const acceptCookie = getAcceptCookie();

  const open = visible && !acceptCookie;

  const handleAccept = () => {
    setAcceptCookie();
    setVisible(false);
  };

  if (!canUseDOM()) {
    return null;
  }

  return (
    <CookiesConsentBar
      open={open}
      url={privacyUrl[locale]}
      onAccept={handleAccept}
      isAuthenticated={isAuthenticated}
    />
  );
};

export default CookiesConsent;
