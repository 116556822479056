import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { StickyMenu, FooterMenu } from 'containers/Navigation';
import CookiesConsent from 'containers/Cookies/CookiesConsent';
import DialogsContainer from 'containers/Dialog/DialogsContainer';
import Header from 'containers/Header/Header';
import { refresh } from 'state/modules/auth';

import { setBookingsDirty } from 'utils/bookingSession';
import { getSessionStorageItem, removeSessionStorageItem } from 'utils/webStorage';
import { sessionRedirectKey } from 'utils/constants';
import { canUseDOM } from 'utils/misc';
import { getReloadBookingCookie } from 'utils/cookies/getCookie';
import { deleteReloadBookingCookie } from 'utils/cookies/deleteCookie';
import useAuth from 'hooks/useAuth';
import useIsMobile from 'hooks/screen/useIsMobile';

// Import global styles
// eslint-disable-next-line import/extensions
import './app.css';
import { setLocaleCookie } from 'utils/cookies/setCookie';
import AppRoutes from './AppRoutes';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'flex',
    marginBottom: '20px',
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.typography.pxToRem(theme.breakpoints.values.mbw),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '70px',
    },
  },
}));

const App = ({ locale, messages }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tenant = useSelector(state => state.tenant);
  const auth = useSelector(state => state.auth);
  const { isAuthenticated, redirectTo } = useAuth();
  const isMobile = useIsMobile();
  const { id: tenantId, config } = tenant;
  const tenantHeaders = config.head;
  const { link, meta, titleTemplate } = tenantHeaders;

  // On mount
  useEffect(() => {
    setLocaleCookie(locale);

    // Removing the styles initialized by the server side is required by MUI 4 (https://v4.mui.com/guides/server-rendering/#the-client-side)
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    /*
      If the reload booking cookie is true, we set bookings dirty flag to true in session storage.
      We then set the reload bookings flag to false. The reload bookings cookie is set to true in storefront
      whenever a booking is created or updated (payment).
    */
    if (getReloadBookingCookie() === true.toString()) {
      deleteReloadBookingCookie();
      setBookingsDirty();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sessionStorageRedirectUrl = getSessionStorageItem(sessionRedirectKey);

    // Will be executed when user refreshes the browser
    if (isAuthenticated && auth.session) {
      dispatch(refresh());
    }

    // If there is a redirect url in the session storage, redirect the user to that url after the successful login.
    // This solve the issue with SSO login not being able to redirect properly.
    if (isAuthenticated && sessionStorageRedirectUrl && canUseDOM()) {
      // Destroy the redirect url from the Session Storage once it has been used.
      removeSessionStorageItem(sessionRedirectKey);
      // Prevent loading the application if there is a redirect url. Fixes issue with firefox redirect issue.
      window.location = sessionStorageRedirectUrl;
      return;
    }

    // If there is a redirect url in the url, automatically redirect the user when there is a successful login.
    // This solves the issue where login link isn't redirected properly
    if (isAuthenticated && redirectTo && canUseDOM()) {
      // Prevent loading the application if there is a redirect url. Fixes issue with firefox redirect issue.
      window.location = redirectTo;
    }
  }, [isAuthenticated, redirectTo, dispatch, auth.session]);

  return (
    <>
      <Helmet link={link} meta={meta} titleTemplate={titleTemplate} />
      <IntlProvider
        defaultLocale="en"
        locale={locale}
        messages={messages}
      >
        {/* It's necessary to have this root element with the tenant ID to apply the correct font */}
        <div id={tenantId}>
          <Header />
          <Container className={classes.rootContainer}>
            {isAuthenticated && !isMobile && <StickyMenu />}
            <Container>
              <AppRoutes />
            </Container>
          </Container>
          <CookiesConsent />
          <DialogsContainer />
          {isAuthenticated && isMobile && <FooterMenu />}
        </div>
      </IntlProvider>
    </>
  );
};

App.propTypes = {
  locale: PropTypes.oneOf(['en', 'ja']).isRequired,
  messages: PropTypes.object.isRequired,
};
export default App;
