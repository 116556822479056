import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const authenticated = useSelector(state => state.auth.authenticated);
  const signingUp = useSelector(state => state.auth.signingUp);
  const signedUp = useSelector(state => state.auth.signedUp);
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const redirectTo = useSelector(state => state.auth.redirectTo);
  const session = useSelector(state => state.auth.session);

  return useMemo(
    () => ({
      isAuthenticated: authenticated && token && !signingUp && !signedUp && !!user && typeof user === 'object' && Object.keys(user).length > 0,
      redirectTo,
      signingUp,
      signedUp,
      token,
      session,
    }),
    [authenticated, redirectTo, signedUp, signingUp, token, user, session]
  );
};

export default useAuth;
