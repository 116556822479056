import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { logout } from 'state/modules/auth';
import { getTenantUrl } from 'utils/misc';
import Menu from 'components/Navigation/Menu';

const StickyMenu = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const urls = useSelector(state => state.tenant.urls);

  const website = getTenantUrl(urls, 'website', locale);
  const storefront = getTenantUrl(urls, 'storefront', locale);
  const localizedUrls = { website, storefront };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Menu
      logout={handleLogout}
      tenantUrls={localizedUrls}
    />
  );
};

export default StickyMenu;
