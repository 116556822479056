module.exports = {
  bedConfig: {
    decideLater: 92, // The CMS BedConfig ID for the "I'll decide later" option
  },
  // The payment status defined on the HTM-API and that means that the booking has no due payment
  bookingFullyPaidStatus: ['fullyPaid', 'overPaid'],
  bookingStatusIds: {
    reservation: 1,
    checkedIn: 7,
    checkedOut: 8,
  },
  // The Guest Titles will be stored on CMS when updating a guest, we want them to be capitalized
  guestTitles: ['Mr', 'Mrs', 'Ms', 'Mstr', 'Dr'],
  localStorageKeys: {
    keepSession: 'keepMeLoggedIn',
  },
  sessionRedirectKey: 'redirect',
  // These are the identifiers for Booking Services Groups, these values are defined in GuestCentrix
  servicesItemGroupIds: {
    liftPasses: 3,
    localDining: 4,
    equipmentRental: 5,
    skiSnowboardLesson: 6,
    transfers: 7,
    childCare: 9,
    guestService: 13,
    mountainGuidingAndTours: 15,
    summerActivities: 18,
    summerServices: 20,
    carRental: 29,
    accommodation: 36,
    kiniseko: 32,
    miscellaneous: 33,
    skyeniseko: 34,
  },
};
