/* eslint-disable import/prefer-default-export */
// Utility method to unify all the error message to certain type of error object
export function getMessage(errorObj) {
  if (!errorObj) {
    return { message: 'An unknown error occurred.' };
  }

  // An error from our API
  if ('error' in errorObj) {
    const { code, message, statusCode, ...rest } = errorObj.error;
    return {
      statusCode,
      message,
      code: code || 'InternalServerError',
      ...rest,
    };
  }

  if ('response' in errorObj) {
    const { statusCode, statusText } = errorObj.response;
    return {
      statusCode,
      message: statusText,
      code: statusCode,
    };
  }

  // If it's none of the above, it hasn't come across yet.
  console.warn(
    'Unhandled error type! See utils/errors.js and add handling for this',
    JSON.stringify(errorObj)
  );
  return errorObj;
}
