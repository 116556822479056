import { parseGuestRequestBody } from 'utils/guest';
import config from 'appConfig';
import types from './types';

const {
  LOAD_OWN, LOAD_OWN_SUCCESS, LOAD_OWN_FAIL,
  LOAD_OTHERS, LOAD_OTHERS_SUCCESS, LOAD_OTHERS_FAIL,
  UPDATE, UPDATE_SUCCESS, UPDATE_FAIL,
  CLEAN_UP, UPDATE_CLEAR, CLEAR_TEMPLATES, CLEAR_PROFILE,
} = types;

export function loadProfile() {
  return {
    types: [LOAD_OWN, LOAD_OWN_SUCCESS, LOAD_OWN_FAIL],
    promise: client => client.get('/GuestTemplates/templates?isOwnTemplate=true'),
  };
}

export function loadTemplate() {
  return {
    types: [LOAD_OTHERS, LOAD_OTHERS_SUCCESS, LOAD_OTHERS_FAIL],
    promise: client => client.get('/GuestTemplates/templates?isOwnTemplate=false'),
  };
}

export function updateProfile(data) {
  const [encryptedData, encryptedFile] = parseGuestRequestBody(data);

  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    passportUpload: encryptedFile !== null,
    promise: client => client.patch('/GuestTemplates', { data: encryptedData, file: encryptedFile, timeout: config.serverTimeout }),
  };
}

export function clearUpdateStatus() {
  return { type: UPDATE_CLEAR };
}

export function cleanup() {
  return { type: CLEAN_UP };
}

export function clearProfile() {
  return { type: CLEAR_PROFILE };
}

export function clearTemplates() {
  return { type: CLEAR_TEMPLATES };
}
