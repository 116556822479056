import cookiesParams from './cookiesParams';

function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none`;
}

export function deleteAuthCookie() {
  deleteCookie(cookiesParams.auth.name);
}

export function deleteReloadBookingCookie() {
  deleteCookie(cookiesParams.loadBookingsFlag.name);
}
