import React from 'react';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'components/Errors';
import Loader from 'components/Loader';

const Profile = loadable(() => import(/* webpackChunkName: "Profile" */ './Profile'), {
  fallback: <Loader visible />,
});

const AsyncProfile = () => (
  <ErrorBoundary>
    <Profile />
  </ErrorBoundary>
);

export default AsyncProfile;
