import { useMemo } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useIsMobile = ({ tablet } = { tablet: false }) => {
  const theme = useTheme();
  const size = tablet ? 'md' : 'xs';
  const isMobile = useMediaQuery(theme.breakpoints.down(size));
  return useMemo(() => isMobile, [isMobile]);
};

export default useIsMobile;
