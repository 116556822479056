import size from 'lodash/size';
import { showError, showLoginFail } from 'state/modules/dialog';
import authTypes from 'state/modules/auth/types';

export default ({ dispatch }) => next => action => {
  const { error, type } = action;

  if (size(error) > 0) {
    switch (type) {
      case authTypes.LOGIN_FAIL:
      case authTypes.SEND_LOGIN_LINK_FAIL:
      case authTypes.SSO_FAIL:
        dispatch(showLoginFail());
        break;
      default:
        dispatch(showError(error));
    }
  }

  next(action);
};
