import PropTypes from 'prop-types';
import { PureComponent } from 'react';

export default class Tenant extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return children;
  }
}
