import PropTypes from 'prop-types';
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Panel from 'components/Panel';

const useStyles = makeStyles({
  title: {
    fontWeight: props => (props.boldTitle ? 'bold' : 'normal'),
  },
});

const Message = ({ color, boldTitle, titleColor, children, icon, noBorders, noPaper, outlined, square, title, gutterBottom, childMt }) => {
  const classes = useStyles({ boldTitle });
  const withTitle = typeof title === 'string' && title.length > 0;
  const isStringChildren = typeof children === 'string' && children.length > 0;
  return (
    <Panel gutterBottom={gutterBottom} noBorders={noBorders} noPaper={noPaper} outlined={outlined} square={square}>
      <Grid container direction="column">
        <Grid item container direction="row" alignItems="center">
          <Grid item>
            <Box mr={1} color={color}>
              {icon}
            </Box>
          </Grid>
          <Grid item>
            <Box color={color}>
              <Typography className={classes.title} color={titleColor} variant="subtitle1">
                {/* Render the title. If not available, render the message. */}
                {title || children}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Render the message below if the title was specified. */}
        {
          withTitle && (
            <Grid item>
              <Box mt={childMt || 2}>
                {
                  isStringChildren && (
                    <Typography>
                      {children}
                    </Typography>
                  )
                }
                {
                  !isStringChildren && children
                }
              </Box>
            </Grid>
          )
        }
      </Grid>
    </Panel>
  );
};

Message.propTypes = {
  boldTitle: PropTypes.bool,
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node.isRequired,
  noBorders: PropTypes.bool,
  noPaper: PropTypes.bool, // Indicates to use MUI Paper to wrap the content in the Panel component or not
  titleColor: PropTypes.string,
  outlined: PropTypes.bool,
  square: PropTypes.bool,
  title: PropTypes.string,
  gutterBottom: PropTypes.bool,
  childMt: PropTypes.number,
};

Message.defaultProps = {
  noBorders: false,
  noPaper: false,
  outlined: true,
  square: false,
  gutterBottom: false,
};

export default Message;
