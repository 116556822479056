import { showGuestSuccess } from 'state/modules/dialog';
import guestTypes from './types';

export default ({ dispatch }) => next => action => {
  const { type, result } = action;
  const response = next(action);

  switch (type) {
    case guestTypes.UPDATE_SUCCESS: {
      dispatch(showGuestSuccess({ messageId: 'dialog.message.detailsChange.success', validation: result.validation }));
      break;
    }
    default:
      break;
  }

  return response;
};
