import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { logout } from 'state/modules/auth';
import { getTenantUrl } from 'utils/misc';
import BottomMenu from 'components/Navigation/BottomMenu';

const FooterMenu = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const urls = useSelector(state => state.tenant.urls);
  const website = getTenantUrl(urls, 'website', locale);
  const storefront = getTenantUrl(urls, 'storefront', locale);
  const localizedUrls = { website, storefront };

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <BottomMenu
      logout={onLogout}
      tenantUrls={localizedUrls}
    />
  );
};

export default FooterMenu;
