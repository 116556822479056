import { canUseDOM } from 'utils/misc';

function IsJsonString(str) {
  try {
    JSON.parse(str);
  }
  catch (e) {
    return false;
  }
  return true;
}

// Session Storage Functions
export function setSessionStorageItem(key, value) {
  if (!canUseDOM() || !window.sessionStorage) {
    return;
  }
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorageItem(key) {
  if (!canUseDOM() || !window.sessionStorage) {
    return null;
  }

  const value = window.sessionStorage.getItem(key) || null;

  return IsJsonString(value) ? JSON.parse(value) : value;
}

export function removeSessionStorageItem(key) {
  if (!canUseDOM() || !window.sessionStorage) {
    return;
  }
  window.sessionStorage.removeItem(key);
}

// Local Storage functions
export function setLocalStorageItem(key, value) {
  if (!canUseDOM() || !window.localStorage) {
    return;
  }
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageItem(key) {
  if (!canUseDOM() || !window.localStorage) {
    return null;
  }

  const value = window.localStorage.getItem(key) || null;

  return IsJsonString(value) ? JSON.parse(value) : value;
}

export function removeLocalStorageItem(key) {
  if (!canUseDOM() || !window.localStorage) {
    return;
  }
  window.localStorage.removeItem(key);
}

export function getAuthCookie(cookieStr) {
  const allCookie = {};
  cookieStr.split(/\s*;\s*/).forEach(pair => {
    const cookiePair = pair.split(/\s*=\s*/);
    allCookie[cookiePair[0]] = cookiePair.splice(1).join('=');
  });
  return allCookie?.auth;
}
